import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import type { NewDeliveryDate } from '@/server/gateway/connections/shopware/loaders/loadNewDeliveryDate';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useProductSubscriptionEdit(
  subscription: ProductSubscriptionItem,
) {
  const dialogStore = useDialogStore();

  const quantity = ref(subscription.quantity);
  const interval = ref(subscription.interval);
  const desiredDeliveryDay = ref(subscription.desiredDeliveryDay);

  async function onSubmit(
    subscriptionId: string,
    quantity: number,
    interval: string,
    desiredDeliveryDay: string,
  ) {
    try {
      const site = useSiteIdent();
      const deliveryDay: number = +desiredDeliveryDay;
      const response = await useSecureSessionPost<string>(
        `/api/${site}/shop/product/productSubscription/update/${subscriptionId}`,
        {
          quantity: quantity,
          interval: interval,
          desiredDeliveryDay: deliveryDay,
        },
      );
      if (response) {
        dialogStore.closeDialog(DialogResponses.SUCCESS);
      } else {
        dialogStore.closeDialog(DialogResponses.CANCEL);
      }
    } catch (e: any) {
      dialogStore.closeDialog(DialogResponses.CANCEL);
      handleLoadingError(e);
    }
  }

  async function getNewDeliveryDate(
    subscriptionId: string,
    interval: string,
    desiredDeliveryDay: string,
  ) {
    try {
      const site = useSiteIdent();
      const deliveryDay: number = +desiredDeliveryDay;
      const response = await useSecureSessionPost<NewDeliveryDate>(
        `/api/${site}/shop/product/productSubscription/deliveryDate/load`,
        {
          subscriptionId: subscriptionId,
          interval: interval,
          desiredDeliveryDay: deliveryDay,
        },
      );
      return response?.date;
    } catch (e: any) {
      handleLoadingError(e);
      return null;
    }
  }

  return {
    quantity,
    interval,
    desiredDeliveryDay,
    onSubmit,
    getNewDeliveryDate,
  };
}
